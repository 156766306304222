// Two resources on using Sisense JS in React:
// https://gitlab.com/SisenseJS/react-sample/-/blob/master/src/sisense/dashboard.js
// Recently released beta version of SisenseJS React (2023): https://github.com/sisense/sisensejs-components

import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SisenseContextProvider } from "@sisense/sdk-ui";
import CloseIcon from "@mui/icons-material/Close";
import { stylesheet } from "../../stylesheet";
import {
  DashboardModel,
  FilterDimension,
  InsightsTemplateSelection,
  SIENSE_WIDGET_CALLBACK_ARGS,
  WidgetContainer,
  WidgetModel,
} from "../../report-builder/insights/types";
import { ResizableBox } from "react-resizable";
import { LoadingButton } from "@mui/lab";
import {
  Filter,
  createAttribute,
  createDimension,
  filters,
} from "@sisense/sdk-data";
export interface InsightsPromptSelectionDialogProps extends DialogProps {
  appContext: any;
  sisenseEnv: string;
  selectedDashboard: string;
  context: string;
  dashboards: DashboardModel[];
  onTemplateSelect: (selection: InsightsTemplateSelection[]) => void;
  setSelectedDashboard: React.Dispatch<React.SetStateAction<string>>;
}

export const InsightsPromptSelectionDialog: FunctionComponent<
  InsightsPromptSelectionDialogProps
> = ({
  appContext,
  sisenseEnv,
  selectedDashboard,
  context,
  setSelectedDashboard,
  onTemplateSelect,
  dashboards,
  onClose,
  ...props
}) => {
  const [widgetContainers, setWidgetContainers] = useState<WidgetContainer[]>(
    []
  );
  const [templateSelections, setTemplateSelections] =
    useState<InsightsTemplateSelection[]>();
  const [widgetContainersLoaded, setWidgetContainersLoaded] =
    useState<boolean>(false);
  const [selectedWidgets, setSelectedWidgets] = useState<any[]>([]);
  const [checkboxStates, setCheckboxStates] = useState<any>({});
  const [loadedWidgets, setLoadedWidgets] = useState<any[]>([]);
  const [isResettingFilters, setIsResettingFilters] = useState<boolean>(false);
  const [isInsertingWidgets, setIsInsertingWidgets] = useState<boolean>(false);
  const [toggleStates, setToggleStates] = useState<Map<string, boolean>>();
  const [confirmClicked, setConfirmClicked] = useState<boolean>(false);
  // used to correctly trigger our widgets to render
  const widgetContainerRef = useRef<HTMLDivElement>(null);

  const loadWidgetContainers = useCallback(async () => {
    if (selectedDashboard) {
      return appContext.dashboards
        .load(selectedDashboard)
        .then((dashboard: any) => {
          const containers: WidgetContainer[] = [];
          const initialCheckboxStates: any = {};
          // $$widgetsMap has the actual widget ids
          const widgetIds = Object.keys(dashboard.$$widgets.$$widgetsMap);
          // get widgets array -- 2nd $$widgets contains array of widgets
          const widgets = dashboard.$$widgets.$$widgets;

          // filter out chart types that cannot be rendered
          for (let i = 0; i < widgets.length; i++) {
            const widget = widgets[i].$$model;
            if (
              widget.type === "indicator" ||
              widget.type === "pivot2" ||
              widget.type === "tablewidget" ||
              widget.type === "tablewidgetagg" ||
              widget.type === "richtexteditor" ||
              widget.type === "BloX" ||
              widget.type === "histogramwidget"
            ) {
              continue;
            } else {
              containers.push({
                queryType: undefined,
                widgetId: widgetIds[i],
                id: `widget${i}`,
                title: widget.title,
              });
            }

            initialCheckboxStates[`widget${i}`] = false;
          }

          containers.sort((a, b) => {
            return a.title.localeCompare(b.title);
          });

          setWidgetContainers(containers);
          setCheckboxStates(initialCheckboxStates);
          setToggleStates(
            new Map(widgets.map((widget: any) => [widget.$$model.oid, true]))
          );
          setWidgetContainersLoaded(true);
        });
    }
  }, [appContext.dashboards, selectedDashboard]);

  const handleToggleLegend = (key: string) => {
    const currentWidget = loadedWidgets?.find((w) => w.$$model.oid === key);
    if (!currentWidget) return;

    const updatedToggleStates = new Map(toggleStates);
    const toggled = updatedToggleStates.get(key);
    updatedToggleStates.set(key, !toggled);
    setToggleStates(updatedToggleStates);

    currentWidget.on(
      "beforeviewloaded",
      (
        event: any,
        args: { widget: WidgetModel; element: HTMLElement; options: any }
      ) =>
        onBeforeViewLoadedHandler(
          event,
          args as SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"],
          {
            spacing: [3, 3, 3, 55],
            margin: 1,
            showLegend: updatedToggleStates.get(key),
          }
        )
    );
    currentWidget.refresh();
  };

  const resetState = () => {
    setConfirmClicked(false);
    setSelectedWidgets([]);
    setWidgetContainers([]);
    setLoadedWidgets([]);
    setWidgetContainersLoaded(false);
  };

  const reloadWidgetsForDashboards = useCallback(async () => {
    resetState();
    await loadWidgetContainers();
  }, [loadWidgetContainers]);

  const onBeforeViewLoadedHandler = (
    event: any,
    args: SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"],
    customChartStyles: any = null
  ) => {
    const {
      options, // for chart - final Highcharts options, for scattermap - Object that contains the map
    } = args;

    if (event.type !== "chart/pie") {
      if (options.chart) {
        options.chart.showAxes = true;
      }

      if (!options.xAxis) {
        options.xAxis = {
          labels: {},
        };
      }
      options.xAxis.visible = true;
      options.xAxis.labels.enabled = true;

      if (!options.yAxis) {
        options.yAxis = {};
      }
      options.yAxis.visible = true;
      if (options.legend) {
        options.legend.margin = 20;
      }
    }

    if (event.type === "chart/pie") {
      if (customChartStyles && customChartStyles.spacing) {
        options.chart.spacing = customChartStyles.spacing;
        options.legend.margin = customChartStyles.margin;
      }
    }
    if (!customChartStyles && options.chart) {
      options.chart.height = 400;
    }
    if (customChartStyles && "showLegend" in customChartStyles) {
      options.legend.enabled = customChartStyles.showLegend;
    } else if (options.legend) {
      options.legend.enabled = true;
    }
  };

  const loadWidgets = useCallback(() => {
    if (
      selectedDashboard &&
      widgetContainers.length &&
      widgetContainersLoaded
    ) {
      appContext.dashboards
        .load(selectedDashboard)
        .then((dashboard: DashboardModel) => {
          const widgets: any[] = [];
          widgetContainers.forEach((container: WidgetContainer) => {
            const widget = dashboard.widgets.get(container.widgetId);
            if (!widget) {
              return;
            }

            // https://sisense.dev/reference/embedding/sisense.js/sisense-widget.html
            // .container is the designated DOM element to hold the widget contents
            const widgetElem = document.getElementById(container.id);
            if (widgetElem) {
              widget.container = widgetElem as HTMLDivElement;
              widget.on("beforeviewloaded", (event, args) =>
                onBeforeViewLoadedHandler(
                  event,
                  args as SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"]
                )
              );
            }
            widgets.push(widget);
          });

          setLoadedWidgets(widgets);

          const filtersElem = document.getElementById("filters");
          if (filtersElem) {
            filtersElem.innerHTML = "";
            dashboard.renderFilters(filtersElem as HTMLDivElement);
          }

          dashboard.refresh();
        });
    }
  }, [
    appContext.dashboards,
    selectedDashboard,
    widgetContainers,
    widgetContainersLoaded,
  ]);

  const handleWidgetCheckboxSelect = (
    widgetId: string,
    containerId: string,
    event: any
  ) => {
    const checked = event.target.checked;
    const newCheckboxStates = { ...checkboxStates };
    newCheckboxStates[containerId] = checked;
    setCheckboxStates(newCheckboxStates);

    checked
      ? setSelectedWidgets([...selectedWidgets, widgetId])
      : setSelectedWidgets(selectedWidgets.filter((w) => widgetId !== w));
  };
  /* 
        We want to render widgets dynamically like this -- the problem is that SisenseJS requires a widget container to be loaded on the DOM
        in order to attach the loaded widgets to the DOM. So we have to fetch the widgets from an API to determine how many widgets we have
        and then load Sisense JS to attach the widgets onto the containers.
      */
  const renderWidgetContainers = function (): ReactNode {
    return (
      <div>
        {widgetContainers.map((container: WidgetContainer) => {
          const key = container.widgetId;
          const isChecked = toggleStates?.get(key) || false;
          return (
            <div
              className="widget-container"
              key={container.widgetId}
              ref={widgetContainerRef}
              css={(theme) => ({ marginBottom: theme.spacing(1) })}
            >
              <Box
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  css={(theme) => ({
                    fontWeight: "bold",
                    marginLeft: theme.spacing(1),
                  })}
                >
                  {container.title}
                </span>
                <span>
                  <FormControlLabel
                    style={{ marginLeft: "30px" }}
                    control={
                      <Switch
                        checked={isChecked}
                        onChange={() => {
                          handleToggleLegend(key);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={"Legend"}
                  />
                  <Checkbox
                    checked={checkboxStates[container.id]}
                    onChange={(event: any) => {
                      handleWidgetCheckboxSelect(
                        container.widgetId,
                        container.id,
                        event
                      );
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </span>
              </Box>
              <div
                css={(theme) => ({
                  padding: theme.spacing(1),
                  height: "450px",
                  marginBottom: theme.spacing(1),
                  border: "1.5px solid #c5c5c5",
                  overflow: "hidden",
                })}
              >
                <ResizableBox
                  height={420}
                  width={1000}
                  css={ss.resizable}
                  onResizeStop={() => {
                    handleResize(container.widgetId);
                  }}
                  maxConstraints={[1020, 440]}
                  style={{ border: "2px solid gray" }}
                >
                  <div id={container.id}></div>
                </ResizableBox>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleResize = (widgetId: string) => {
    const currentWidget = loadedWidgets?.find(
      (w) => w.$$model.oid === widgetId
    );
    if (!currentWidget) return;

    currentWidget.on(
      "beforeviewloaded",
      (
        event: any,
        args: { widget: WidgetModel; element: HTMLElement; options: any }
      ) =>
        onBeforeViewLoadedHandler(
          event,
          args as SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"],
          {
            spacing: [3, 3, 3, 55],
            margin: 1,
            showLegend: toggleStates?.get(widgetId) || false,
          }
        )
    );
    currentWidget.refresh();
  };

  const onConfirmMultiselect = useCallback(async () => {
    if (!templateSelections) return;

    setIsInsertingWidgets(false);
    setConfirmClicked(false);
    resetState();

    onTemplateSelect(templateSelections);
  }, [onTemplateSelect, templateSelections]);

  useEffect(() => {
    if (selectedDashboard) {
      reloadWidgetsForDashboards();
    }
  }, [reloadWidgetsForDashboards, selectedDashboard]);

  useEffect(() => {
    if (widgetContainerRef.current && widgetContainersLoaded) {
      loadWidgets();
    }
  }, [loadWidgets, widgetContainerRef, widgetContainersLoaded]);

  const resetDashboardFilters = useCallback(async () => {
    setIsResettingFilters(true);

    // because we are persisting changes, a dashboard.refresh() will not reset the filters
    // we need to access the $$dashboard object's filters on any loaded widget to call resetFilters()
    // the generic dashboard object's $$filtersScope is not defined which is why we are using the widget's $$dashboard object
    const widget = loadedWidgets[0];
    widget?.$$dashboard.$$filtersScope.resetFilters();
    setIsResettingFilters(false);
  }, [loadedWidgets]);

  const handleConfirmClicked = useCallback(async () => {
    setIsInsertingWidgets(true);
    const selections: InsightsTemplateSelection[] = [];
    const filters = await buildFilters();
    widgetContainers.forEach((c: WidgetContainer) => {
      if (checkboxStates[c.id]) {
        const resized = document.getElementById(c.id);
        if (resized) {
          const selection: InsightsTemplateSelection = {
            title: c.title,
            widgetId: c.widgetId,
            dashboardId: selectedDashboard,
            height: resized?.clientHeight,
            width: resized?.clientWidth,
            filters: filters,
          };
          selections.push(selection);
        }
      }
    });
    setTemplateSelections(selections);
    setConfirmClicked(true);
  }, [checkboxStates, selectedDashboard, widgetContainers]);

  const getFilterType = (type: string): string => {
    switch (type) {
      case "numeric":
        return "numeric-attribute";
      case "text":
      default:
        return "text-attribute";
    }
  };

  const addFiltersToQuery = (filterJaqls: any[], qFilters: Filter[]) => {
    filterJaqls.forEach((filterJaql: any) => {
      const dim = filterJaql.dim;
      const type = filterJaql.datatype;
      const columnName = filterJaql.column.replace(/\s/g, "");
      const filterDimension = createDimension({
        name: "Dimension",
        FilterDim: createAttribute({
          name: columnName,
          type: getFilterType(type),
          expression: dim,
        }),
      }) as FilterDimension;

      /*
       * If the filter's column name is "name", then the createAttribute function will make the name the value of the dimension.
       * E.g. "name" -> "dim_pintypes_name"
       * In order to correctly set the filter member, we have to get the filterDimension by the new name.
       */
      const filterDimensionName =
        columnName === "name" || columnName === "type"
          ? dim.replace(/\./g, "_").replace(/^\[|\]$/g, "")
          : columnName;
      const member = filters.members(
        filterDimension[filterDimensionName],
        filterJaql.filter.members
      );
      qFilters.push(member);
    });
  };

  const buildFilters = async (): Promise<Filter[]> => {
    try {
      const response = await appContext.$$http.get(
        `${sisenseEnv}/api/v1/dashboards/${selectedDashboard}`
      );
      const dashboard = response.data;
      if (!dashboard || dashboard.filters.length === 0) {
        return [];
      }

      const qFilters: Filter[] = [];
      dashboard.filters.forEach((filter: any) => {
        const filterJaqls = [];
        if (filter.jaql) {
          const jaql = filter.jaql;
          if (
            context === "building" &&
            (jaql.dim === "[dim_buildings.organization_name]" ||
              jaql.dim === "[dim_buildings.building_name]")
          ) {
            return;
          }
          if (jaql.filter && jaql.filter.members) {
            filterJaqls.push(jaql);
          }
        } else if (filter.levels) {
          filter.levels.forEach((level: any) => {
            if (
              context === "building" &&
              (level.dim === "[dim_buildings.organization_name]" ||
                level.dim === "[dim_buildings.building_name]")
            ) {
              return;
            }
            if (level.filter.members && level.filter.members.length) {
              filterJaqls.push(level);
            }
          });
        }
        if (filterJaqls.length) {
          addFiltersToQuery(filterJaqls, qFilters);
        }
      });

      return qFilters;
    } catch (error) {
      return [];
    }
  };

  return (
    <div>
      {/* Using Compose SDK with Sisense JS to render tables */}
      <SisenseContextProvider url={sisenseEnv} ssoEnabled={true}>
        {
          <Dialog
            fullWidth
            maxWidth="xl"
            {...props}
            PaperProps={{
              style: {
                height: "100%",
              },
            }}
            css={ss.dialog}
          >
            <DialogTitle
              component={Box}
              css={() => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              })}
            >
              <Typography variant="h6" color="inherit">
                Insert Insights Prompts
              </Typography>
              <IconButton
                color="inherit"
                onClick={() => {
                  resetState();
                  onClose?.({}, "escapeKeyDown");
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent css={{ overflow: "hidden" }}>
              <Box
                css={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  css={(theme) => ({
                    display: "flex",
                    gap: theme.spacing(1),
                    marginTop: theme.spacing(2),
                  })}
                >
                  <FormControl css={{ flex: "1 0 auto" }}>
                    <InputLabel id="dashabords-select-label">
                      Dashboard
                    </InputLabel>
                    <Select
                      label="Dashboard"
                      labelId="dashabords-select-label"
                      onChange={(e) => {
                        // set our dashbaord on the parent
                        setSelectedDashboard(e.target.value);
                        reloadWidgetsForDashboards();
                      }}
                      value={selectedDashboard}
                      size="medium"
                      variant="outlined"
                    >
                      {dashboards?.map((dashboard) => (
                        <MenuItem value={dashboard.oid} key={dashboard._id}>
                          {dashboard.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedDashboard && (
                    <>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={resetDashboardFilters}
                        disabled={isResettingFilters || !widgetContainersLoaded}
                      >
                        RESET FILTERS
                      </Button>
                      <LoadingButton
                        loading={isInsertingWidgets}
                        variant="contained"
                        color="primary"
                        disabled={isInsertingWidgets || !selectedWidgets.length}
                        onClick={handleConfirmClicked}
                      >
                        Confirm
                      </LoadingButton>
                      {confirmClicked &&
                        templateSelections &&
                        onConfirmMultiselect()}
                    </>
                  )}
                </Box>
                <Box
                  css={(theme) => ({
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(1),
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    overflow: "hidden",
                  })}
                >
                  <Box
                    css={(theme) => ({
                      flex: 1,
                      overflowY: "auto",
                      paddingRight: theme.spacing(1),
                    })}
                  >
                    {renderWidgetContainers()}
                  </Box>
                  <div
                    id="filters"
                    css={(theme) => ({
                      padding: theme.spacing(2),
                      flexBasis: "25%",
                    })}
                  ></div>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        }
      </SisenseContextProvider>
    </div>
  );
};

const ss = stylesheet({
  dialog: {
    display: "flex",
    flexDirection: "column",
  },
  resizable: {
    position: "relative",
    overflow: "hidden",
    "& .react-resizable-handle": {
      position: "absolute",
      width: 35,
      height: 35,
      bottom: 0,
      right: 0,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      backgroundPosition: "bottom right",
      padding: "0 3px 3px 0",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      boxSizing: "border-box",
      cursor: "se-resize",
    },
  },
  app: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-between",
    ".widget": {
      marginBottom: 7,
      border: "1.5px solid #c5c5c5",
      borderRadius: 4,
    },
  },
});
